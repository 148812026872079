import { getDeviceId } from '../utils/tracking';
import { getSignupUrl, getRedirectTrackingUrl, getUrlWithViralParams } from '../utils/urls';
import enviro from 'enviro';
export function getTrackedSignupUrl({
  flow,
  viralLinkType,
  environment = enviro.getShort(),
  additionalParams
}) {
  return getSignupUrl(flow, viralLinkType, getDeviceId(), environment === 'qa', additionalParams);
}
export function getTrackedIntermediateUrl({
  viralLinkType,
  additionalParams,
  portalId,
  environment,
  redirectPage
}) {
  const deviceId = getDeviceId();
  return getRedirectTrackingUrl({
    viralLinkType,
    portalId,
    deviceId,
    environment,
    redirectPage: getUrlWithViralParams(redirectPage, viralLinkType, deviceId, additionalParams)
  });
}