/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "he-il": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%n %u",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "₪"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": ",",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "בייט",
              "other": "בתים"
            },
            "gb": "ג'יגה-בייט",
            "kb": "קילו-בייט",
            "mb": "מגה-בייט",
            "tb": "טרה-בייט"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " ו",
            "lastWordConnector": " ו"
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " or ",
            "lastWordConnector": ", or "
          }
        }
      }
    }
  }
}