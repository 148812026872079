/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "hu": {
    "number": {
      "currency": {
        "format": {
          "delimiter": "",
          "format": "%n %u",
          "precision": 0,
          "separator": ",",
          "significant": true,
          "strip_insignificant_zeros": true
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 2,
        "separator": ",",
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": "Byte",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        }
      }
    }
  }
}