import { RequiredQueryParamsByFlow } from './RequiredQueryParamsByFlow';
export const validateRequiredQueryParams = (flow, searchString) => {
  const lowerCaseSearchString = searchString.toLocaleLowerCase();
  const requiredParamsList = RequiredQueryParamsByFlow.get(flow);
  const searchParams = new URLSearchParams(lowerCaseSearchString);
  if (!requiredParamsList) return;
  requiredParamsList.forEach(param => {
    if (!searchParams.has(param)) {
      throw new Error(`${param} is required for ${flow} flow, please add it to the query string`);
    }
  });
};