export const onReady = fn => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};
export const toggleClassName = (el, className) => el.classList.contains(className) ? el.classList.remove(className) : el.classList.add(className);
export const toggleARIABooleanAttribute = (el, attributeName) => {
  const isTrue = el.getAttribute(attributeName) === 'true';
  return el.setAttribute(attributeName, isTrue ? 'false' : 'true');
};
export const attachStylesheet = (href, container) => {
  const link = document.createElement('link');
  link.href = href;
  link.rel = 'stylesheet';
  if (container) {
    container.appendChild(link);
  } else {
    document.body.appendChild(link);
  }
};
export const parseHtmlElementFromString = htmlString => new DOMParser().parseFromString(htmlString.trim(), 'text/html').body.firstChild;
export const newlineRegex = /\r?\n|\r/g;
export const makeInlineStyle = inlineCssString => inlineCssString.replace(newlineRegex, '').trim();
export const getBrandingElements = wrapper => {
  const brandingWidget = wrapper.querySelector('#hs-cmsf-branding');
  const brandingWidgetButton = wrapper.querySelector('.hs-cmsf-branding__button');
  const brandingWidgetDropdown = wrapper.querySelector('#hs-cmsf-branding__dropdown');
  return {
    brandingWidget,
    brandingWidgetButton,
    brandingWidgetDropdown
  };
};

// appends additional defensive visibility styles for hs-cmsf-branding custom element
export const appendDefensiveElementStyles = () => {
  const wrapperStyles = `
    hs-cmsf-branding {
      display: block !important;
      visibility: visible !important;
      opacity: 1 !important;
    }
  `;
  const style = document.createElement('style');
  style.textContent = wrapperStyles;
  document.body.appendChild(style);
};