/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "de": {
    "number": {
      "format": {
        "precision": 3,
        "separator": ",",
        "delimiter": ".",
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "precision": 0,
          "format": "%n %"
        }
      },
      "currency": {
        "format": {
          "strip_insignificant_zeros": false,
          "unit": "€",
          "precision": 2,
          "format": "%n %u",
          "delimiter": ".",
          "separator": ","
        }
      },
      "human": {
        "storage_units": {
          "units": {
            "byte": " Byte",
            "kb": "kb",
            "mb": "mb",
            "gb": "gb",
            "tb": "tb"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " und ",
            "lastWordConnector": " und "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " oder ",
            "lastWordConnector": " oder "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 Tausend",
              "other": "0 Tausend"
            },
            "10000": {
              "one": "00 Tausend",
              "other": "00 Tausend"
            },
            "100000": {
              "one": "000 Tausend",
              "other": "000 Tausend"
            },
            "1000000": {
              "one": "0 Million",
              "other": "0 Millionen"
            },
            "10000000": {
              "one": "00 Millionen",
              "other": "00 Millionen"
            },
            "100000000": {
              "one": "000 Millionen",
              "other": "000 Millionen"
            },
            "1000000000": {
              "one": "0 Milliarde",
              "other": "0 Milliarden"
            },
            "10000000000": {
              "one": "00 Milliarden",
              "other": "00 Milliarden"
            },
            "100000000000": {
              "one": "000 Milliarden",
              "other": "000 Milliarden"
            },
            "1000000000000": {
              "one": "0 Billion",
              "other": "0 Billionen"
            },
            "10000000000000": {
              "one": "00 Billionen",
              "other": "00 Billionen"
            },
            "100000000000000": {
              "one": "000 Billionen",
              "other": "000 Billionen"
            }
          },
          "short": {
            "1000": {
              "one": "0 Tsd.",
              "other": "0 Tsd."
            },
            "10000": {
              "one": "00 Tsd.",
              "other": "00 Tsd."
            },
            "100000": {
              "one": "000 Tsd.",
              "other": "000 Tsd."
            },
            "1000000": {
              "one": "0 Mio",
              "other": "0 Mio"
            },
            "10000000": {
              "one": "00 Mio",
              "other": "00 Mio"
            },
            "100000000": {
              "one": "000 Mio",
              "other": "000 Mio"
            },
            "1000000000": {
              "one": "0 Mrd",
              "other": "0 Mrd"
            },
            "10000000000": {
              "one": "00 Mrd",
              "other": "00 Mrd"
            },
            "100000000000": {
              "one": "000 Mrd",
              "other": "000 Mrd"
            },
            "1000000000000": {
              "one": "0 Bio.",
              "other": "0 Bio."
            },
            "10000000000000": {
              "one": "00 Bio.",
              "other": "00 Bio."
            },
            "100000000000000": {
              "one": "000 Bio.",
              "other": "000 Bio."
            }
          }
        }
      }
    }
  }
}