/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "pt-pt": {
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": ".",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " e ",
            "lastWordConnector": " e "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " ou ",
            "lastWordConnector": " ou "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 mil",
              "other": "0 mil"
            },
            "10000": {
              "one": "00 mil",
              "other": "00 mil"
            },
            "100000": {
              "one": "000 mil",
              "other": "000 mil"
            },
            "1000000": {
              "one": "0 milhão",
              "other": "0 milhões"
            },
            "10000000": {
              "one": "00 milhão",
              "other": "00 milhões"
            },
            "100000000": {
              "one": "000 milhão",
              "other": "000 milhões"
            },
            "1000000000": {
              "one": "0 bilhão",
              "other": "0 bilhões"
            },
            "10000000000": {
              "one": "00 bilhão",
              "other": "00 bilhões"
            },
            "100000000000": {
              "one": "000 bilhão",
              "other": "000 bilhões"
            },
            "1000000000000": {
              "one": "0 trilhão",
              "other": "0 trilhões"
            },
            "10000000000000": {
              "one": "00 trilhão",
              "other": "00 trilhões"
            },
            "100000000000000": {
              "one": "000 trilhão",
              "other": "000 trilhões"
            }
          },
          "short": {
            "1000": {
              "one": "0 mil",
              "other": "0 mil"
            },
            "10000": {
              "one": "00 mil",
              "other": "00 mil"
            },
            "100000": {
              "one": "000 mil",
              "other": "000 mil"
            },
            "1000000": {
              "one": "0 mi",
              "other": "0 mi"
            },
            "10000000": {
              "one": "00 mi",
              "other": "00 mi"
            },
            "100000000": {
              "one": "000 mi",
              "other": "000 mi"
            },
            "1000000000": {
              "one": "0 bi",
              "other": "0 bi"
            },
            "10000000000": {
              "one": "00 bi",
              "other": "00 bi"
            },
            "100000000000": {
              "one": "000 bi",
              "other": "000 bi"
            },
            "1000000000000": {
              "one": "0 tri",
              "other": "0 tri"
            },
            "10000000000000": {
              "one": "00 tri",
              "other": "00 tri"
            },
            "100000000000000": {
              "one": "000 tri",
              "other": "000 tri"
            }
          }
        }
      }
    }
  }
}