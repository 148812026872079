/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "fr-ca": {
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "octet",
              "other": "octets"
            },
            "gb": "Go",
            "kb": "ko",
            "mb": "Mo",
            "tb": "To"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " et ",
            "lastWordConnector": " et "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " ou ",
            "lastWordConnector": " ou "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 millier",
              "other": "0 mille"
            },
            "10000": {
              "one": "00 millier",
              "other": "00 mille"
            },
            "100000": {
              "one": "000 millier",
              "other": "000 mille"
            },
            "1000000": {
              "one": "0 million",
              "other": "0 millions"
            },
            "10000000": {
              "one": "00 million",
              "other": "00 millions"
            },
            "100000000": {
              "one": "000 million",
              "other": "000 millions"
            },
            "1000000000": {
              "one": "0 milliard",
              "other": "0 milliards"
            },
            "10000000000": {
              "one": "00 milliard",
              "other": "00 milliards"
            },
            "100000000000": {
              "one": "000 milliards",
              "other": "000 milliards"
            },
            "1000000000000": {
              "one": "0 billion",
              "other": "0 billions"
            },
            "10000000000000": {
              "one": "00 billions",
              "other": "00 billions"
            },
            "100000000000000": {
              "one": "000 billions",
              "other": "000 billions"
            }
          },
          "short": {
            "1000": {
              "one": "0 k",
              "other": "0 k"
            },
            "10000": {
              "one": "00 k",
              "other": "00 k"
            },
            "100000": {
              "one": "000 k",
              "other": "000 k"
            },
            "1000000": {
              "one": "0 M",
              "other": "0 M"
            },
            "10000000": {
              "one": "00 M",
              "other": "00 M"
            },
            "100000000": {
              "one": "000 M",
              "other": "000 M"
            },
            "1000000000": {
              "one": "0 Md",
              "other": "0 Md"
            },
            "10000000000": {
              "one": "00 Md",
              "other": "00 Md"
            },
            "100000000000": {
              "one": "000 Md",
              "other": "000 Md"
            },
            "1000000000000": {
              "one": "0 Bn",
              "other": "0 Bn"
            },
            "10000000000000": {
              "one": "00 Bn",
              "other": "00 Bn"
            },
            "100000000000000": {
              "one": "000 Bn",
              "other": "000 Bn"
            }
          }
        }
      }
    }
  }
}