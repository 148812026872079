/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "fi": {
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "precision": 0,
          "format": "%n %"
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "tavu",
              "other": "tavua"
            },
            "gb": "GB",
            "kb": "kB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " ja ",
            "lastWordConnector": " ja "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " tai ",
            "lastWordConnector": " tai "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 tuhat",
              "other": "0 tuhatta"
            },
            "10000": {
              "one": "00 tuhatta",
              "other": "00 tuhatta"
            },
            "100000": {
              "one": "000 tuhatta",
              "other": "000 tuhatta"
            },
            "1000000": {
              "one": "0 miljoona",
              "other": "0 miljoonaa"
            },
            "10000000": {
              "one": "00 miljoonaa",
              "other": "00 miljoonaa"
            },
            "100000000": {
              "one": "000 miljoonaa",
              "other": "000 miljoonaa"
            },
            "1000000000": {
              "one": "0 miljardi",
              "other": "0 miljardia"
            },
            "10000000000": {
              "one": "00 miljardia",
              "other": "00 miljardia"
            },
            "100000000000": {
              "one": "000 miljardia",
              "other": "000 miljardia"
            },
            "1000000000000": {
              "one": "0 biljoona",
              "other": "0 biljoonaa"
            },
            "10000000000000": {
              "one": "00 biljoonaa",
              "other": "00 biljoonaa"
            },
            "100000000000000": {
              "one": "000 biljoonaa",
              "other": "000 biljoonaa"
            }
          },
          "short": {
            "1000": {
              "one": "0 t.",
              "other": "0 t."
            },
            "10000": {
              "one": "00 t.",
              "other": "00 t."
            },
            "100000": {
              "one": "000 t.",
              "other": "000 t."
            },
            "1000000": {
              "one": "0 milj.",
              "other": "0 milj."
            },
            "10000000": {
              "one": "00 milj.",
              "other": "00 milj."
            },
            "100000000": {
              "one": "000 milj.",
              "other": "000 milj."
            },
            "1000000000": {
              "one": "0 mrd.",
              "other": "0 mrd."
            },
            "10000000000": {
              "one": "00 mrd.",
              "other": "00 mrd."
            },
            "100000000000": {
              "one": "000 mrd.",
              "other": "000 mrd."
            },
            "1000000000000": {
              "one": "0 bilj.",
              "other": "0 bilj."
            },
            "10000000000000": {
              "one": "00 bilj.",
              "other": "00 bilj."
            },
            "100000000000000": {
              "one": "000 bilj.",
              "other": "000 bilj."
            }
          }
        }
      }
    }
  }
}