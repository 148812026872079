function getHsVars() {
  return window.hsVars;
}
export function getPortalId() {
  const hsVars = getHsVars();
  return hsVars && typeof hsVars.portal_id === 'number' ? hsVars.portal_id : undefined;
}
export function getEnv() {
  const hsVars = getHsVars();
  return hsVars && typeof hsVars.app_hs_base_url === 'string' ? hsVars.app_hs_base_url.includes('hubspotqa.com') ? 'qa' : 'prod' : undefined;
}