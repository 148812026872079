/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "zh-tw": {
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": ",",
          "format": "%n%",
          "precision": 0
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "位元組",
              "other": "位元組"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": "、",
            "twoWordsConnector": "和",
            "lastWordConnector": "和"
          },
          "exclusive": {
            "wordsConnector": "、",
            "twoWordsConnector": "或",
            "lastWordConnector": "或"
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0k",
              "other": "0k"
            },
            "10000": {
              "one": "0萬",
              "other": "0萬"
            },
            "100000": {
              "one": "00萬",
              "other": "00萬"
            },
            "1000000": {
              "one": "000萬",
              "other": "000萬"
            },
            "10000000": {
              "one": "0000萬",
              "other": "0000萬"
            },
            "100000000": {
              "one": "0億",
              "other": "0億"
            },
            "1000000000": {
              "one": "00億",
              "other": "00億"
            },
            "10000000000": {
              "one": "000億",
              "other": "000億"
            },
            "100000000000": {
              "one": "0000億",
              "other": "0000億"
            },
            "1000000000000": {
              "one": "0兆",
              "other": "0兆"
            },
            "10000000000000": {
              "one": "00兆",
              "other": "00兆"
            },
            "100000000000000": {
              "one": "000兆",
              "other": "000兆"
            }
          },
          "short": {
            "1000": {
              "one": "0k",
              "other": "0k"
            },
            "10000": {
              "one": "0萬",
              "other": "0萬"
            },
            "100000": {
              "one": "00萬",
              "other": "00萬"
            },
            "1000000": {
              "one": "000萬",
              "other": "000萬"
            },
            "10000000": {
              "one": "0000萬",
              "other": "0000萬"
            },
            "100000000": {
              "one": "0億",
              "other": "0億"
            },
            "1000000000": {
              "one": "00億",
              "other": "00億"
            },
            "10000000000": {
              "one": "000億",
              "other": "000億"
            },
            "100000000000": {
              "one": "0000億",
              "other": "0000億"
            },
            "1000000000000": {
              "one": "0兆",
              "other": "0兆"
            },
            "10000000000000": {
              "one": "00兆",
              "other": "00兆"
            },
            "100000000000000": {
              "one": "000兆",
              "other": "000兆"
            }
          }
        }
      }
    }
  }
}