export class URLSearchParamsPolyfill {
  constructor(query) {
    this.searchParams = Object.entries(query);
  }
  toString() {
    return this.searchParams.map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
  }
}
export const getURLSearchParams = () => {
  if (URLSearchParams) {
    return URLSearchParams;
  }
  return URLSearchParamsPolyfill;
};