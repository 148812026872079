/*** IMPORTS FROM imports-loader ***/
import { hns } from "../../../../../../../workspace/cms-free-branding/cms-free-branding-lib/static/hns-shim.js";

export default {
  "sv": {
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "kr"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "precision": 0,
          "format": "%n %"
        }
      },
      "human": {
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        },
        "array": {
          "inclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " och ",
            "lastWordConnector": " och "
          },
          "exclusive": {
            "wordsConnector": ", ",
            "twoWordsConnector": " eller ",
            "lastWordConnector": ", eller "
          }
        },
        "abbreviated": {
          "long": {
            "1000": {
              "one": "0 tusen",
              "other": "0 tusen"
            },
            "10000": {
              "one": "00 tusen",
              "other": "00 tusen"
            },
            "100000": {
              "one": "000 tusen",
              "other": "000 tusen"
            },
            "1000000": {
              "one": "0 miljon",
              "other": "0 miljoner"
            },
            "10000000": {
              "one": "00 miljon",
              "other": "00 miljoner"
            },
            "100000000": {
              "one": "000 miljoner",
              "other": "000 miljoner"
            },
            "1000000000": {
              "one": "0 miljard",
              "other": "0 miljarder"
            },
            "10000000000": {
              "one": "00 miljarder",
              "other": "00 miljarder"
            },
            "100000000000": {
              "one": "000 miljarder",
              "other": "000 miljarder"
            },
            "1000000000000": {
              "one": "0 biljon",
              "other": "0 biljoner"
            },
            "10000000000000": {
              "one": "00 biljoner",
              "other": "00 biljoner"
            },
            "100000000000000": {
              "one": "000 biljoner",
              "other": "000 biljoner"
            }
          },
          "short": {
            "1000": {
              "one": "0 tn",
              "other": "0 tn"
            },
            "10000": {
              "one": "00 tn",
              "other": "00 tn"
            },
            "100000": {
              "one": "000 tn",
              "other": "000 tn"
            },
            "1000000": {
              "one": "0 mn",
              "other": "0 mn"
            },
            "10000000": {
              "one": "00 mn",
              "other": "00 mn"
            },
            "100000000": {
              "one": "000 mn",
              "other": "000 mn"
            },
            "1000000000": {
              "one": "0 md",
              "other": "0 md"
            },
            "10000000000": {
              "one": "00 md",
              "other": "00 md"
            },
            "100000000000": {
              "one": "000 md",
              "other": "000 md"
            },
            "1000000000000": {
              "one": "0 bn",
              "other": "0 bn"
            },
            "10000000000000": {
              "one": "00 bn",
              "other": "00 bn"
            },
            "100000000000000": {
              "one": "000 bn",
              "other": "000 bn"
            }
          }
        }
      }
    }
  }
}