// I18n setup:
import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider';
import registerAllData from 'I18n/init/register/sync/registerAllData';
import lang from 'i2l?query=sporks!../lang/en.lyaml';
import { getBrandingHtmlElement } from './html/brandingHtmlElement';
// @ts-expect-error bender-url isn't supported in TypeScript yet
import hsCmsFreeBrandingStyles from '!bender-url!../css/hsCmsFreeBranding.css';
import { appendDefensiveElementStyles, attachStylesheet, getBrandingElements, onReady, parseHtmlElementFromString, toggleARIABooleanAttribute, toggleClassName } from './utils/domHelpers';
const collapsedClassName = 'hs-cmsf-branding__dropdown--collapsed';
const initCustomElement = brandingHtmlElement => {
  window.customElements.define('hs-cmsf-branding', class extends HTMLElement {
    constructor() {
      super();
      this.toggleWidget = ({
        target
      }) => {
        if (!(target instanceof HTMLElement)) {
          return; // context: https://github.com/Microsoft/TypeScript/issues/29540
        }
        const {
          brandingWidgetDropdown,
          brandingWidgetButton
        } = this;
        if (!brandingWidgetDropdown || !brandingWidgetButton) {
          return;
        }
        if (!window.hsInEditor) {
          if (this.contains(target)) {
            toggleClassName(brandingWidgetDropdown, collapsedClassName);
            toggleARIABooleanAttribute(brandingWidgetButton, 'aria-expanded');
          } else {
            brandingWidgetDropdown.classList.add(collapsedClassName);
            brandingWidgetButton.setAttribute('aria-expanded', 'false');
          }
        }
      };
      this.root = this.attachShadow({
        mode: 'closed'
      });
      this.brandingWidget = null;
      this.brandingWidgetButton = null;
      this.brandingWidgetDropdown = null;
      attachStylesheet(hsCmsFreeBrandingStyles, this.root);
    }
    static get observedAttributes() {
      return ['style', 'class'];
    }
    connectedCallback() {
      const shadowRoot = this.root;
      shadowRoot.appendChild(brandingHtmlElement);
      const {
        brandingWidget,
        brandingWidgetButton,
        brandingWidgetDropdown
      } = getBrandingElements(shadowRoot);
      this.brandingWidget = brandingWidget;
      this.brandingWidgetButton = brandingWidgetButton;
      this.brandingWidgetDropdown = brandingWidgetDropdown;
      const isBrandingInserted = this.brandingWidget && this.brandingWidgetButton && this.brandingWidgetDropdown;
      if (!isBrandingInserted) {
        return;
      }
      document.addEventListener('click', this.toggleWidget);
    }
    attributeChangedCallback(attrName) {
      if (attrName === 'class' && this.getAttribute('class')) {
        this.removeAttribute('class');
      }
      if (attrName === 'style' && this.getAttribute('style')) {
        this.removeAttribute('style');
      }
    }
    disconnectedCallback() {
      document.body.appendChild(this);
    }
  });
  const brandingElement = parseHtmlElementFromString(`<hs-cmsf-branding></hs-cmsf-branding>`);
  document.body.appendChild(brandingElement);
  appendDefensiveElementStyles();
};
const initBranding = wrapper => {
  const {
    brandingWidget,
    brandingWidgetButton,
    brandingWidgetDropdown
  } = getBrandingElements(wrapper);
  const isBrandingInserted = brandingWidget && brandingWidgetButton && brandingWidgetDropdown;
  if (!isBrandingInserted) {
    return;
  }
  document.addEventListener('click', ({
    target
  }) => {
    if (!(target instanceof HTMLElement)) {
      return; // context: https://github.com/Microsoft/TypeScript/issues/29540
    }
    if (brandingWidget.contains(target)) {
      toggleClassName(brandingWidgetDropdown, collapsedClassName);
      toggleARIABooleanAttribute(brandingWidgetButton, 'aria-expanded');
    } else {
      brandingWidgetDropdown.classList.add(collapsedClassName);
      brandingWidgetButton.setAttribute('aria-expanded', 'false');
    }
  });
};
const insertBranding = () => {
  const brandingHtmlElement = getBrandingHtmlElement();
  if ('customElements' in window) {
    initCustomElement(brandingHtmlElement);
  } else {
    attachStylesheet(hsCmsFreeBrandingStyles);
    document.body.appendChild(brandingHtmlElement);
    initBranding(document);
  }
};
const start = () => {
  if (!window.hsInEditor) {
    insertBranding();
  }
};
const I18nPublicProvider = createPublicI18nProvider();
const langAvailable = Promise.all([I18nPublicProvider.register(lang), registerAllData(I18nPublicProvider)]);
langAvailable.then(() => onReady(start)).catch(error => {
  console.error(`Error iniatilizing I18n provider ${error}`);
});